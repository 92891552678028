import React, { useState, useEffect } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { RedYellowGreen } from '../../constants/red_yellow_green';

const RadioOption = ({ value, label, description, inlineDescription, isSelected, color, disabled }) => (
  <FormControlLabel
    value={value}
    disabled={disabled}
    control={
      <Radio
        sx={{
          '&.MuiRadio-root': {
            paddingTop: '0px',
          },
          '&.Mui-checked': {
            color: color,
          },
          '&.Mui-disabled': {
            opacity: 1,
          },
        }}
      />
    }
    label={
      <Box>
        <Typography variant="body1" sx={{ color: disabled ? 'text.primary' : 'inherit' }}>
          {label}
          {inlineDescription && isSelected && description && (
            <Typography variant="body2" component="span" color="text.secondary" sx={{ ml: 1 }}>
              - {description}
            </Typography>
          )}
        </Typography>
        {!inlineDescription && (
          <Collapse in={isSelected} timeout={300}>
            {description && (
              <Typography
                variant="body2"
                color="text.primary"
                sx={{
                  mt: 0.5,
                  mb: 0.5,
                  opacity: isSelected ? 1 : 0,
                  transition: 'opacity 300ms ease-in-out',
                }}
              >
                {description}
              </Typography>
            )}
          </Collapse>
        )}
      </Box>
    }
    sx={{
      alignItems: 'flex-start',
      '&.Mui-disabled': {
        opacity: 1,
      },
    }}
  />
);

const RadioStatusGroup = ({
  options,
  label,
  value: externalValue,
  onChange: externalOnChange,
  name = 'radiogroup-status',
  inlineDescription = false,
  disabled = false,
  error = null,
}) => {
  const [internalValue, setInternalValue] = useState(null);

  useEffect(() => {
    if (externalValue) {
      setInternalValue(externalValue);
    }
  }, [externalValue]);

  const handleChange = (event) => {
    if (!disabled) {
      const newValue = event.target.value;
      setInternalValue(newValue);
      if (externalOnChange) {
        externalOnChange(event);
      }
    }
  };

  const currentValue = internalValue;

  const renderOptions = () => {
    if (disabled && currentValue) {
      const selectedOption = options.find((option) => option.value === currentValue);
      return selectedOption ? [selectedOption] : options;
    }
    return options;
  };

  return (
    <FormControl disabled={disabled} error={error !== null}>
      {label && (
        <FormLabel component="legend" sx={{ color: disabled ? 'text.disabled' : 'text.primary' }}>
          {label}
        </FormLabel>
      )}
      <RadioGroup aria-label={name} name={name} value={currentValue || ''} onChange={handleChange}>
        {renderOptions().map((option) => (
          <RadioOption
            key={option.value}
            value={option.value}
            label={option.label}
            description={option.description}
            inlineDescription={inlineDescription}
            isSelected={currentValue === option.value}
            color={option.color || RedYellowGreen.green}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
      <FormHelperText sx={{ marginLeft: 0 }}>{error}</FormHelperText>
    </FormControl>
  );
};

export default RadioStatusGroup;
